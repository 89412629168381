import React, { Component } from 'react'
import { List, Card } from 'antd';
export default class ItemList extends Component {
  render() {
    const { items, size = 'small', footer = null, grid = null, header = null} = this.props;
    return (
      <div>
        <List
            size={size}
            bordered
            grid={grid}
            footer={footer}
            header={header}
            locale={{
              emptyText: "Ожидание данных"
            }}
            dataSource={items}
            renderItem={item => (<List.Item>
              {item}
            </List.Item>)}
        />
      </div>
    )
  }
}

