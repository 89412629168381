import React, { Component } from 'react'
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios'
import List from '../components/List'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import LocationChip from '../components/LocationChip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTeamspeak } from '@fortawesome/free-brands-svg-icons';
import { lightGreen, green } from '@material-ui/core/colors'
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { Divider } from 'antd';
const ym = window.ym;
const styles = theme => ({
  colorSwitchBase: {
    '&$colorChecked': {
      color: lightGreen['A700'],
      '& + $colorBar': {
        backgroundColor: green[500],
      },
    },
  },
  colorBar: {},
  colorChecked: {},
})
const apiUrl = "https://status.top-ts.ru/locations/json";
class StatusPage extends Component {
  constructor(){
      super();
      this.state={
        locations:[]
      }
    }
  componentDidMount = async () => {
    ym(40136605, 'hit','/status')
    const locations = await axios.get(apiUrl).then(response=>response.data);
    this.setState({ locations });
  }
  render() {
    const { classes } = this.props;
    const { locations } = this.state;
    const items = locations.map(location=>{
      return <LocationChip location={location} />
    })
    ym('hit', '/status');
    return (
      <Container>
        <Helmet>
          <title>
            Статус серверов | TOP-TS.RU
          </title>
          <meta name="description" content="Статус серверов голосового хостинга TOP-TS.RU" />
        </Helmet>
        <h4>Статус серверов</h4>
        <List items={items} header={"Ознакомьтесь с нашими локациями. При заказе Вы можете выбрать где следует разместить новый сервер."}
            footer={(
                <div>
                  <div>
                  Для подключения к тестовому серверу нажмите на иконку&nbsp;
                  <FontAwesomeIcon 
                    style={{verticalAlign:'middle'}} 
                    color="black"
                    icon={faTeamspeak} 
                    fixedWidth size={"lg"} 
                  />
                  &nbsp;рядом с локацией
                </div>
                <Divider />
                <div>
                  Чтобы узнать более подробно о локации наведите на флаг страны&nbsp;
                  <FontAwesomeIcon 
                    style={{verticalAlign:'middle'}} 
                    color="black"
                    icon={faFlag} 
                    fixedWidth size={"lg"} 
                  />
                  &nbsp;рядом с локацией
                </div>
              </div>
            )} 
          />
      </Container>
    )
  }
}
export default withStyles(styles)(StatusPage);
