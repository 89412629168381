import React, { Component } from 'react'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Rate, Divider, Tag } from 'antd';
export default class HomePageReviewCard extends Component {
  render() {
    const { mark, text, client, register, date } = this.props;
    return (
    <div>
        <Card style={{minWidth: 275}}>
            <CardHeader
            title={client}
            subheader={<Rate value={mark} disabled />}
            style={{
                paddingBottom: 0
            }}
            />
            <CardContent>  
                <div style={{marginBottom:12}}>
                    <Tag style={{margin: "0 4px 4px 0"}} color="#108ee9">Клиент с {register}</Tag> 
                    <Tag style={{margin: "0 4px 4px 0"}} color="geekblue">Отзыв написан {date}</Tag>
                </div>
                <Typography component="p" dangerouslySetInnerHTML={{ __html: text }}>
                </Typography>
            </CardContent>
        </Card>
    </div>
    )
  }
}
