import React, { Component } from 'react'
import { Tag } from 'antd'
export default class WhatIsButton extends Component {
  render() {
    return (
      <div>
        <Tag color="#108ee9">#108ee9</Tag>
      </div>
    )
  }
}
