import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FolderIcon from '@material-ui/icons/Folder';
const styles = theme=>({
  secondary:{
    color: "black"
  },
  primary: {
    fontWeight: 700
  }
})

class Feature extends Component {
  render() {
    const { classes } = this.props;
    const { primary, secondary, icon } = this.props;
    return ( 
      <ListItem>
        <ListItemIcon>
          <FontAwesomeIcon fixedWidth icon={icon} size={"2x"}  color={this.props.iconColor || 'green'} />
        </ListItemIcon>
        <ListItemText
          classes={{secondary:classes.secondary, primary: classes.primary}}
          primary={primary}
          secondary={secondary ? secondary : null}
        />
      </ListItem>
    );
  }
}
 
export default withStyles(styles)(Feature);