import React, { Component } from 'react'
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue'
import indigo from '@material-ui/core/colors/indigo'
const api = "https://api.top-ts.ru/v1/statistics"
const StatisticsIcons = {
    'online': {
        text: "Онлайн на серверах",
        icon: <FontAwesomeIcon icon={faUserCheck} fixedWidth size="lg" />,
    },
    'servers': {
        text: "Серверов создано",
        icon: <FontAwesomeIcon icon={faServer} fixedWidth size="lg" />,
    }
}
const styles = theme => {
    const height = 40;
    return {
        colorPrimary:{
            '&:hover, &:focus': {
                backgroundColor: indigo['A700']
            },
            '&:active': {
                backgroundColor: indigo[900]
            },
        },
        chip: {
            margin: theme.spacing.unit / 2,
            height,
            borderRadius: height / 2
        },
        label: {
            color: 'black',
            fontSize: 16
        },
        icon: {
            fontSize: 24,
            color: 'black'
        }
    }
}
class HostingStatistics extends Component {
    state={
        online: 0, 
        servers: 0,
        loading: true
    }
    componentDidMount(){
        axios.all([
            axios.get(api+ "/latest"),
            axios.get(api+ "/servers")
        ])
        .then(axios.spread((onlineResponse, serversResponse)=>{
            const online = onlineResponse.data.Clients;
            const servers = serversResponse.data.servers;
            this.setState({
                online, 
                servers,
                loading: false
            })
        }))
    }
    render() {
        const { classes } = this.props;
        return (
        <div>
            <h3>Статистика хостинга</h3>
            <Grid container justify="center">
                {['online','servers'].map((value, key)=>(
                    <Chip key={key}
                        icon={StatisticsIcons[value].icon}
                        label={StatisticsIcons[value].text + ": "+this.state[value]}
                        clickable={false}
                        className={classes.chip}
                        classes={{
                            label: classes.label,
                            icon: classes.icon
                        }}
                        variant="default"
                    />
                ))}
            </Grid>
        </div>
        )
    }
}
export default withStyles(styles)(HostingStatistics);
