import React from 'react';
import { BrowserRouter as Router, Route, Link, withRouter, NavLink } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSignal,faHome, faQuestion, faUserAlt, faBell, faUserCheck, faUser
} from '@fortawesome/free-solid-svg-icons';
import { faTeamspeak, faVk } from '@fortawesome/free-brands-svg-icons'
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Badge } from 'antd';


const styles = {
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 15,
  },
  title: {
    flexGrow: 1,
  },
  // serverTokens: {
  //   "&$selected": {
  //     backgroundColor: blue[700],
  //     "& $primary, & $icon": {
  //       color: "white"
  //     },
  //     "&:hover": {
  //       backgroundColor: blue[700],
  //       color: "white"
  //     }
  //   }
  // },
  MenuItem: {
    "&$selected": {
      "& $primary, & $icon": {
        color: "blue"
      },
      "&:hover": {
        color: "black"
      }
    },
    "&:hover": {
      "& $primary, & $icon": {
        color: "black"
      },
    }
  },
  selected: {},
  primary: {},
  icon: {}
};

class SwipeableTemporaryDrawer extends React.Component {
  state = {
    left: false,
    tab:"/"
  };
  componentDidMount = () => {
    const ActivePage = this.props.location.pathname;
    this.setState({tab:ActivePage});
  }
  componentDidUpdate() {
    const ActivePage = this.props.location.pathname;
    if (this.state.tab !== ActivePage) this.setState({ tab:ActivePage });
  }
  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };
  handleItemClick = (route) => {
    this.setState({ tab:route });
  }
  isActive = (match,location) => {
    console.log(location)
    return match.path === this.state.tab;
  }
  
  render() {
    const { classes } = this.props;
    const MenuItem = ({ link, icon, text, spin=false }) => {
      return (
              <ListItem
                button
                selected={this.state.tab === link}
                onClick={()=>{ this.handleItemClick(link) }}
                classes={{root: classes.MenuItem, selected: classes.selected}}
              >
                <ListItemIcon className={classes.icon}>
                  <FontAwesomeIcon icon={icon} fixedWidth size={"lg"} spin={spin} />
                </ListItemIcon>
                <ListItemText primary={text} classes={{
                  primary:classes.primary
                }} />
              </ListItem>
      )
    }
    const sideList = (
      <div className={classes.list}>
      
        <List component="nav">
          <Link to="/">
            {MenuItem({ link: "/", icon: faHome, text: "Главная" })}
          </Link>

          <Link to="/rent">
            {MenuItem({ link: "/rent", icon: faTeamspeak , text: "Аренда" })}
          </Link>
          {/* <Link to="/faq">
            {MenuItem({ link: "/faq", icon: faQuestion , text: "Частые вопросы" })}
          </Link> */}
          <Link to="/reviews">
            {MenuItem({ link: "/reviews", icon: faUserCheck, text: "Отзывы клиентов" })}
          </Link>
          <Link to="/status">
            {MenuItem({ link: "/status", icon: faSignal, text: "Статус серверов" })}
          </Link>
          <a target="_blank" rel="noopener" href="//my.top-ts.ru/clientarea.php" rel="noopener noreferrer">
            {MenuItem({ link: null, icon: faUserAlt, text: "Личный кабинет" })}
          </a>
          <a target="_blank" rel="noopener" href="//vk.com/top_ts" rel="noopener noreferrer">
            {MenuItem({ link: null, icon: faVk, text: "Мы ВКонтакте" })}
          </a>
        </List>
      </div>
    );

    return (
      <div>
        <div className={classes.root} style={{marginBottom:0}} >
          <AppBar position={"sticky"} className={classes.blueColor}>
            <Toolbar>
              <IconButton onClick={this.toggleDrawer('left', true)} className={classes.menuButton} color="inherit" aria-label="Open drawer">
                <MenuIcon />
              </IconButton>
              <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                TOP-TS <FontAwesomeIcon icon={faTeamspeak} fixedWidth/> <p style={{fontSize:14}}>На страже ваших побед!</p>
              </Typography>
              <a href="https://my.top-ts.ru/clientarea.php" target="_blank" rel="noopener noreferrer">
                <IconButton>
                  <FontAwesomeIcon icon={faUser} fixedWidth  size="sm" style={{color: 'white'}}/>
                </IconButton>
              </a>
            </Toolbar>
          </AppBar>

          <SwipeableDrawer
            open={this.state.left}
            onClose={this.toggleDrawer('left', false)}
            onOpen={this.toggleDrawer('left', true)}
          >
            <div
              tabIndex={0}
              role="button"
              onClick={this.toggleDrawer('left', false)}
              onKeyDown={this.toggleDrawer('left', false)}
            >
              {sideList}
            </div>
          </SwipeableDrawer>
        </div>
      </div>
    );
  }
}

SwipeableTemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(SwipeableTemporaryDrawer));