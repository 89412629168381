import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import { Route, Link, Switch, Redirect, withRouter } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap'
import { Rate, Divider, Tag } from 'antd';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { pink } from '@material-ui/core/colors';
import { Button } from '@material-ui/core';
import { faVk } from '@fortawesome/free-brands-svg-icons';
const ym = window.ym;

class ReviewsPage extends Component {
  state={
    reviews: []
  }
  async componentDidMount(){
    const api = "https://api.top-ts.ru/billing";
    const reviews = await axios.get(api+"/reviews").then(response=>response.data);
    ym(40136605, 'hit','/reviews')
    this.setState({ reviews });
  }
  render() {
    const { reviews } = this.state;
    return (
      <div>
        <Helmet>
          <title>Отзывы клиентов | Хостинг голосовых серверов TOP-TS.RU</title>
          <meta name="description" content="Отзывы наших клиентов | TOP-TS.RU" />
          <meta property="og:description" content="Отзывы наших клиентов | TOP-TS.RU"/>
          <meta property="og:title" content="Отзывы наших клиентов | TOP-TS.RU" />
          <meta property="og:url" content="https://top-ts.ru/reviews" />
        </Helmet>
        <Container>
            <p style={{color: 'black'}}>Наш хостинг постоянно развивается и причина этому- наши клиенты <FontAwesomeIcon icon={faHeart} fixedWidth size={"lg"} color={pink["A400"]} /></p>
            <p style={{color: 'black'}}>В рядах наших клиентов пользователи не только из СНГ регионов, но и и стран Европы.</p>
            <p style={{color: 'black'}}>Мы несомненно гордимся что наши труды выходят за рамки родной страны и удовлетворяют требования большого количества игроков к качественной связи!</p>
            {reviews.map((review, key) => (
              <div key={key}>
                <Divider />
                <b>{review.client}</b> <Rate value={review.mark} disabled />
                <div style={{color: 'black'}} dangerouslySetInnerHTML={{ __html: review.text }} />
                <Tag color="#108ee9">Клиент с {review.register}</Tag>
                <Tag color="geekblue">Отзыв написан {review.date}</Tag>
              </div>
            ))}
            <Divider />
            <p style={{color: 'black'}}>Всё ещё остались вопросы ?&nbsp;
              <a href="//vk.me/top_ts">
                <Button color="primary" variant="contained">
                  <FontAwesomeIcon icon={faVk} fixedWidth size="lg" />&nbsp;Связаться с нами
                </Button>
              </a><br /> Мы готовы ответить на любые ваши вопросы!
            </p>
        </Container>
      </div>
    )
  }
}
export default ReviewsPage;
