import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button } from '@material-ui/core';

const _ = require('lodash')
const lowSlots = _.range(10,100);
const muchSlots = _.range(100, 301, 20);
const slots = [ ...lowSlots, ...muchSlots ];
const ym = window.ym;
const styles = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      color: 'white'
    },
    formControl: {
      margin: theme.spacing.unit,
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing.unit * 2,
    },
    whiteText: {
        color: 'white',
        fontSize: 18
    },
    whiteColor: {
        color: 'white'
    },
    /////input styling
  });
class HomePageSlotsSelect extends Component {
    fired = false;
    state = {
        slots: 30,
        labelWidth: 0,
    };
    componentDidMount() {
        this.setState({
            labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
        });
      let Order = JSON.parse(localStorage.getItem('Order'));
      if (!Order || Order == null){
          Order = {
              server: {
                slots: 30
              },
              profile: {}
          }
          localStorage.setItem('Order',JSON.stringify(Order))
      }

      const { server: userServer, profile: userProfile } = Order;

      if (!userServer && !userProfile)
      {
          localStorage.setItem('Order',JSON.stringify(Order))
      }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleConversion(){
      if (!this.fired){
        ym(40136605, 'reachGoal', 'orderForm_toBilling')
        window.gtag('event', 'conversion', {
          'send_to': 'AW-921434411/EWPtCJeayfIBEKvyr7cD',
          'event_callback': function (event) {
            console.log("Adwords event success")
            console.log(event)
          }
        });
        this.fired = true;
      }
      return false;
    }
    
    render() {
    const { classes } = this.props;
    return (
        <div>
        <FormControl variant="outlined" className={classes.formControl}>
        <FormGroup>
          <InputLabel
            ref={ref => {
              this.InputLabelRef = ref;
            }}
            className={classes.whiteText}
            classes={{
                root: classes.cssLabel,
                focused: classes.cssFocused,
              }}
            htmlFor="slotsInput"
          >
            Слоты *
          </InputLabel>
          <Select
            autoWidth={true}
            value={this.state.slots}
            onChange={this.handleChange}
            className={classes.whiteText}
            classes={{
                icon: classes.whiteColor,
            }}
            inputProps={{
                classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
            }}
            input={
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                name="slots"
                id="slotsInput"
              />
            }
          >
            {slots.map((value, key)=>
                //console.log(value);
                <MenuItem key={key} className="text-center" value={value}>{value} слотов</MenuItem>
            )}
          </Select>
          </FormGroup>
        </FormControl>
          <a href={`//my.top-ts.ru/cart.php?a=add&pid=2&configoption[5]=${this.state.slots}&billingcycle=monthly`}>
              <Button onClick={()=>{ this.handleConversion()}} style={{padding: 20, marginTop: 8}} variant="contained" color="primary">Дальше</Button>
          </a>
        </div>
    )
  }
}

export default withStyles(styles)(HomePageSlotsSelect);
