import React, { Component } from 'react'
import { Container, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Popover, Tag } from 'antd'
import axios from 'axios';
import { Helmet } from "react-helmet";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faMicrophoneAlt, faMicrophone, faUsersCog, faCog, faGlobe, faBell, faHeart, faHourglass, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import LicenseOrPirate from '../components/LicenseVSPirate'
import HomePageTopFeatureCard from '../components/Card';
import { Input, Select, Button as AButton, Popconfirm } from 'antd';
import Button from '@material-ui/core/Button';
import { Button as MUIButton } from '@material-ui/core'
import pink from '@material-ui/core/colors/pink'
import blue from '@material-ui/core/colors/blue'
import { Divider } from 'antd';
import List from '@material-ui/core/List'
//import TestLocationCard from '../components/TestLocationCard';
import HomePageReviewCard from '../components/HomePageReviewCard';
import { faTeamspeak } from '@fortawesome/free-brands-svg-icons'
import { faRubleSign, faStar, faUserCheck, faShieldAlt, faCheckCircle, faServer, faWrench, faEquals } from '@fortawesome/free-solid-svg-icons'
import { faLifeRing } from '@fortawesome/free-regular-svg-icons'
import { Button as BSButton } from 'reactstrap';
import OrderForm from '../components/OrderForm';
import Feature from '../components/Feature';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo'
import red from '@material-ui/core/colors/red'
import WhyLicenseData from '../info/whylicense';
import WhyLicenseList from '../components/List';
import ModalMessage from '../components/ModalMessage'
import Slider from "react-slick";
import { purple, grey } from '@material-ui/core/colors';
//import PaymentMethods from '../components/PaymentMethods';
import Loadable from "react-loadable";
import HostingStatistics from '../components/HostingStatistics';
import HomePageSlotsSelect from '../components/HomePageSlotsSelect'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

const TestLocationCard = Loadable({
    loader: () => import("../components/TestLocationCard"),
    loading: () => (<p>Тестовая локация</p>)
});
const PaymentMethods = Loadable({
    loader: () => import("../components/PaymentMethods"),
    loading: () => (<p>Платёжные методы</p>)
});
const qs = require('qs');
const Option = Select.Option;
const InputGroup = Input.Group;
const domains = [
    'top-ts.ru',
    'tswp.ru',
    'xts3.ru'
]
const getTranslation = (id) => {
    return <FormattedHTMLMessage id={id}
        defaultMessage="To get started, edit <code>src/App.js</code> and save to reload."
        description="Text on main page" />
}
const styles = theme => {
    //console.log(theme.palette)
    return {
        root: {
            flexGrow: 0,
        },
        loveIcon: {
            color: theme.palette.primary.main
        },
        whiteText: {
            color: 'white'
        },
        whiteUnderline: {
            borderBottom: "white"
        }
    }
};
const ym = window.ym;
class HomePage extends Component {
    constructor() {
        super();
        this.state = {
            whyLicenseModal: false,
            address: "",
            domain: "top-ts.ru",
            addressBusy: false,
            reviews: [],
        }
        this.doCheckTimeout = null;
        this.handleSlotsChange = this.handleSlotsChange.bind(this);
        this.hideWhyLicense = this.hideWhyLicense.bind(this);
        this.showWhyLicense = this.showWhyLicense.bind(this);
    }
    async componentDidMount() {
        ym(40136605, 'hit', '/')
        const api = "https://api.top-ts.ru/billing"
        //const reviews = await axios.get(api+"/reviews").then(response=>response.data);
        //this.setState({ reviews });
    }
    getAddressBusy() {
        return {
            message: this.state.address ? (`Адрес ${this.state.addressBusy ? 'занят' : 'свободен'}`) : "Введите адрес",
            color: this.state.address ? (this.state.addressBusy ? 'red' : 'green') : "red"
        }
    }
    change(key, value) {
        this.setState({ [key]: value }, () => {
            clearTimeout(this.doCheckTimeout);
            this.doCheckTimeout = setTimeout(() => {
                this.doCheck();
            }, 300)
        })
    }
    async doCheck() {
        const isBusy = await axios.post('https://my.top-ts.ru/check.php', qs.stringify({
            address: this.state.address,
            domain: this.state.domain
        }), {
            headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
        }).then(response => {
            return response.data;
        })
        if (isBusy === 1 && this.state.addressBusy === false) {
            this.setState({ addressBusy: true })
        } else {
            this.setState({ addressBusy: false })
        }
    }
    showWhyLicense() {
        this.setState({ whyLicenseModal: true });
    }
    hideWhyLicense() {
        this.setState({ whyLicenseModal: false });
    }
    handleSlotsChange(e) {
        this.setState({ slots: e.target.value })
    }

    render() {
        const { classes } = this.props;
        const { address, domain, reviews } = this.state;
        const { message: addressCheckMessage, color: addressCheckColor } = this.getAddressBusy();
        const { addressBusy } = this.state;

        const settings = {
            speed: 500,
            infinite: true,
            dots: true,
            autoplay: true,
            autoplaySpeed: 5000,
            responsive: [
                {
                    breakpoint: 2000,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,

                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div>
                <Helmet>
                    <title>Аренда лицензионного сервера TeamSpeak3 в Москве и Европе | TOP-TS.RU</title>
                    <meta name="description" content="Хостинг лицензионных голосовых серверов с гарантией! Постоянные акции и скидки! Только у нас: при заказе от 30 слотов +10 слотов бесплатно!" />
                    <meta property="og:description" content="Лицензионный (ATHP) хостинг голосовых серверов с гарантией | TOP-TS.RU" />
                    <meta property="og:title" content="Хостинг лицензионных TS серверов по самой низкой цене | TOP-TS.RU" />
                    <meta property="og:url" content="https://top-ts.ru/" />
                </Helmet>
                <div style={{
                    position: 'relative',
                    zIndex: 0,
                    paddingTop: 30,
                    backgroundImage: "url('https://top-ts.ru/game-bg.jpg')",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    minHeight: 600
                }}>
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: "#26262b",
                        opacity: '0.8'
                    }} />
                    <Container style={{
                        position: 'relative'
                    }}>
                        {/* <h2 style={{
                        color: 'white'
                    }}>
                        Качественная связь - залог лёгкой победы!
                    </h2>
                    <h5 className={classes.whiteText}>Коммуникация с союзниками значительно повышает результат на полях сражений!</h5> */}
                        <Divider />
                        <h1 className={`text-center ${classes.whiteText}`} >Лицензионный хостинг серверов TeamSpeak 3</h1>
                        <h3 className={`text-center ${classes.whiteText}`}>Арендуйте лицензионный голосовой сервер всего за <b>12 рублей</b>/слот</h3>
                        <Row className="justify-content-center align-items-center">
                            <HomePageSlotsSelect />
                        </Row>
                        <Row className="justify-content-center align-items-center">
                            <p className={`text-center ${classes.whiteText}`} style={{
                                fontSize: 18
                            }}>При заказе сервера от 30 слотов мы начисляем бесплатно +10 слотов</p>
                            <p className={`text-center ${classes.whiteText}`} style={{
                                fontSize: 18
                            }}>С нами Вы забудете о постоянных проблемах пиратских серверов! <br />(проблемы со входом, вылеты и прочие недоразумения, о которых Вас не предупреждали)</p>
                            <p className={`text-center ${classes.whiteText}`} style={{
                                fontSize: 18
                            }}>При переходе с пиратского хостинга сможем помочь с переносом данных, а также предоставим скидку!</p>
                        </Row>
                    </Container>
                    <div style={{
                        width: '100%',
                        padding: 20,
                        position: 'absolute',
                        backgroundColor: 'black',
                        bottom: 0,
                    }} className="text-center">
                        <div className={`${classes.whiteText}`} style={{ fontSize: 18 }}>
                            <h5 className={`${classes.whiteText}`}>Почему именно мы ? <FontAwesomeIcon icon={faArrowCircleDown} size="lg" fixedWidth /></h5>
                        </div>
                    </div>
                </div>
                {/* <Container id="order">
                    <h1>Аренда голосового сервера</h1>
                    <OrderForm />
                </Container> */}
                <Container className="mt-2">
                    <h4>Гарантия низкой цены!</h4>
                    Нашли где-то лицензионные сервера в свободной продаже дешевле чем у нас ?
                    Предоставим скидку!
                </Container>
                <Divider />
                <Container>
                    <h4>Узнайте почему стоит выбрать TOP-TS</h4>

                    <List dense={false}>
                        <Grid container spacing={24}>
                            <Grid item md>
                                <Feature primary="Самая низкая цена" secondary={["Только лицензионные сервера всего за 9.1 рублей/слот. ", <a key={1} rel="noopener noreferrer" target="_blank" href="//vk.me/top_ts">Нашли дешевле ? Снизим цену!</a>]}
                                    icon={faRubleSign}
                                    iconColor={indigo[500]}
                                />
                            </Grid>
                            <Grid item md>
                                <Feature primary={["Официальная ", <a key={1} rel="noopener noreferrer" href="https://hosting.teamspeakusa.com/?athp=8cd40ce899de16d7d4f7b7dad61afa3d" target="_blank">ATHP лицензия</a>]}
                                    secondary={["Арендуйте голосовой сервер без лишних посредников и наценок. ",
                                        <AButton key={0} onClick={this.showWhyLicense} type="primary">Почему это важно ?</AButton>
                                    ]}
                                    icon={faCheckCircle}
                                />
                            </Grid>
                            <Grid item md>
                                <Feature primary="Автоматическая установка"
                                    secondary="Ваш сервер доступен сразу же после оплаты. Данные о новом сервере отправляются на почту"
                                    icon={faHourglass}
                                    iconColor="black"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={24}>
                            <Grid item md>
                                <Feature primary="Акция 30 + 10 слотов" secondary={["При заказе сервера от 30 слотов и более ", <FontAwesomeIcon key={1} icon={faEquals} />, " 10 слотов бесплатно"]}
                                    icon={faStar}
                                    iconColor="gold"
                                />
                            </Grid>
                            <Grid item md>
                                <Feature primary="Полное управление"
                                    secondary="Вы получаете полные привилегии на управление сервером"
                                    icon={faWrench}
                                    iconColor="black"
                                />
                            </Grid>
                            <Grid item md>
                                <Feature primary="Защита от DDoS атак"
                                    secondary="Ваши сервера размещаются на высокопроизводительном оборудовании"
                                    icon={faShieldAlt}
                                    iconColor={deepPurple[900]}
                                />
                            </Grid>
                        </Grid>


                        <Feature primary="Всегда готовы помочь"
                            secondary="Наша служба поддержки всегда готова помочь решить проблему или ответить на интересующие вопросы"
                            icon={faLifeRing}
                            iconColor={red[900]}
                        />
                    </List>
                </Container>
                <ModalMessage
                    title="Почему стоит выбрать лицензионные сервера ?"
                    visible={this.state.whyLicenseModal}
                    hideFunction={this.hideWhyLicense}
                    message={[
                        "Арендовав лицензионный сервер Вы оградите себя от множества проблем пиратских хостингов, например: ",
                        <WhyLicenseList key={1} size="small" items={WhyLicenseData} />,
                    ]}
                    footer={[
                        <MUIButton key="submit" color="primary" onClick={this.hideWhyLicense}>
                            Хорошо
                        </MUIButton>
                    ]}
                />

                <Divider />
                <Container>
                    <h4>Тестовые сервера на наших локациях</h4>
                    <p>При оформлении заказа Вы можете указать на какой локации следует установить сервер</p>
                    <Grid container spacing={24} justify={"center"}>
                        <Grid item>
                            <TestLocationCard country="Россия" city="Москва" short="ru" address="public.top-ts.ru" />
                        </Grid>
                        <Grid item>
                            <TestLocationCard country="Польша" city="Варшава" short="pl" address="public_eu.top-ts.ru" />
                        </Grid>
                    </Grid>
                </Container>
                <Divider />
                <Container>
                    <h3>Мы принимаем</h3>
                    <PaymentMethods />
                </Container>

                <div style={{ backgroundColor: "black" }} className="pb-4 pt-4 mb-5 mt-5">
                    <Container>
                        <h3 style={{ color: 'white' }}>Сомневаетесь в выборе ?</h3>
                        <p style={{ color: 'white' }}>Наш хостинг постоянно развивается и причина этому- наши клиенты <FontAwesomeIcon icon={faHeart} fixedWidth size={"2x"} className={classes.loveIcon} /></p>
                        <p style={{ color: 'white' }}>Ознакомьтесь с отзывами клиентов о нашем хостинге и сделайте свой выбор!&nbsp;
                            <Link to="/reviews">
                                <Button color="primary" variant="contained">Перейти</Button>
                            </Link>
                        </p>
                    </Container>
                </div>
                <Container className="mt-5 mb-5">
                    <ModalMessage
                        title="Почему стоит выбрать лицензионные сервера ?"
                        visible={this.state.whyLicenseModal}
                        hideFunction={this.hideWhyLicense}
                        message={[
                            "Арендовав лицензионный сервер Вы оградите себя от множества проблем пиратских хостингов, например: ",
                            <WhyLicenseList key={1} size="small" items={WhyLicenseData} />,
                        ]}
                        footer={[
                            <Button key="submit" color="primary" onClick={this.hideWhyLicense}>
                                Хорошо
                            </Button>
                        ]}
                    />
                    {/* <HostingStatistics /> */}
                </Container>
            </div>
        )
    }
}
export default withStyles(styles)(HomePage);