import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

class FAQ extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta name="description" content="Часто задаваемые вопросы. Хостинг TOP-TS.RU" />
          <meta property="og:description" content="Ответы на вопросы, которые могут возникнуть перед арендой сервера | TOP-TS.RU"/>
          <meta property="og:title" content="Часто задаваемые вопросы | TOP-TS.RU" />
          <meta property="og:url" content="https://top-ts.ru/faq" />
        </Helmet>
        FAQ
      </div>
    )
  }
}
export default FAQ;
