import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';
import ym from 'react-yandex-metrika';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faChevronRight,
  faChevronCircleRight,
  faUserPlus,
  faCartPlus,
  faUserSecret,
  faHandshake,
  faSignal,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons';
import { faUser, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { Divider } from 'antd';
import grey from '@material-ui/core/colors/grey';
class Footer extends Component {
  handleDelete() {
    return;
  }
  render() {
    return (
      <div style={{ backgroundColor: 'white', paddingTop: 30, marginTop: 30 }}>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <h3 style={{ color: 'black' }}>Профиль</h3>
              <a href="//my.top-ts.ru/register.php">
                <Chip
                  avatar={
                    <Avatar>
                      <FontAwesomeIcon icon={faUserPlus} fixedWidth size={'1x'} />
                    </Avatar>
                  }
                  label="Регистрация"
                  clickable
                  onDelete={this.handleDelete}
                  deleteIcon={<FontAwesomeIcon icon={faChevronCircleRight} fixedWidth size={'lg'} />}
                />
              </a>
              <Divider style={{ backgroundColor: grey[400] }} />
              <a href="//my.top-ts.ru/clientarea.php">
                <Chip
                  avatar={
                    <Avatar>
                      <FontAwesomeIcon icon={faUser} fixedWidth size={'1x'} />
                    </Avatar>
                  }
                  label="Личный кабинет"
                  clickable
                  onDelete={this.handleDelete}
                  deleteIcon={<FontAwesomeIcon icon={faChevronCircleRight} fixedWidth size={'lg'} />}
                />
              </a>
              <Divider style={{ backgroundColor: grey[400] }} />
              <a href="//my.top-ts.ru/pwreset.php">
                <Chip
                  avatar={
                    <Avatar>
                      <FontAwesomeIcon icon={faQuestionCircle} fixedWidth size={'1x'} />
                    </Avatar>
                  }
                  label="Забыли пароль ?"
                  clickable
                  onDelete={this.handleDelete}
                  deleteIcon={<FontAwesomeIcon icon={faChevronCircleRight} fixedWidth size={'lg'} />}
                />
              </a>
              {/* <Divider style={{backgroundColor: grey[400]}} /> */}
            </Col>
            <Col xs={12} md={6}>
              <h3 style={{ color: 'black' }}>Ссылки</h3>
              <Link to="/rent">
                <Chip
                  avatar={
                    <Avatar>
                      <FontAwesomeIcon icon={faCartPlus} fixedWidth size={'1x'} />
                    </Avatar>
                  }
                  label="Заказать"
                  clickable
                  color="primary"
                  onDelete={this.handleDelete}
                  deleteIcon={<FontAwesomeIcon icon={faChevronCircleRight} fixedWidth size={'lg'} />}
                />
              </Link>
              <Divider style={{ backgroundColor: grey[400] }} />
              <Link to="/reviews">
                <Chip
                  avatar={
                    <Avatar>
                      <FontAwesomeIcon icon={faUserCheck} fixedWidth size={'1x'} />
                    </Avatar>
                  }
                  label="Отзывы"
                  clickable
                  color="primary"
                  onDelete={this.handleDelete}
                  deleteIcon={<FontAwesomeIcon icon={faChevronCircleRight} fixedWidth size={'lg'} />}
                />
              </Link>
              <Divider style={{ backgroundColor: grey[400] }} />
              <Link to="/status">
                <Chip
                  avatar={
                    <Avatar>
                      <FontAwesomeIcon icon={faSignal} fixedWidth size={'1x'} />
                    </Avatar>
                  }
                  label="Статус"
                  clickable
                  color="primary"
                  onDelete={this.handleDelete}
                  deleteIcon={<FontAwesomeIcon icon={faChevronCircleRight} fixedWidth size={'lg'} />}
                />
              </Link>
              {/* <Divider style={{backgroundColor: grey[400]}} />
                <Link to="/reselling">
                  <Chip
                    avatar={<Avatar><FontAwesomeIcon icon={faHandshake} fixedWidth size={'1x'}/></Avatar>}
                    label="Реселлинг"
                    clickable
                    color="primary"
                    onDelete={this.handleDelete}
                    deleteIcon={<FontAwesomeIcon icon={faChevronCircleRight} fixedWidth size={'lg'} />}
                  />
                </Link> */}
            </Col>
          </Row>
        </Container>

        <div
          style={{
            backgroundColor: 'black',
            marginTop: '30px',
            color: 'white',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          <Container>
            <div>
              <span>ИП: Байков Илья Сергеевич</span>
            </div>
            <div>
              <span>ИНН: 691506164288</span>
            </div>
            <div>
              <span>ОГРНИП: 317695200061233</span>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
export default Footer;
