import React, { Component } from 'react';
import { Avatar, Chip } from '@material-ui/core'
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faTeamspeak } from '@fortawesome/free-brands-svg-icons';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from 'antd';
import indigo from '@material-ui/core/colors/indigo';
const humanize = require('humanize-duration');
const humanizeUptime = humanize.humanizer({
    language: 'ru',
    units: ['d', 'h', 'm'],
    round: true,
    delimiter: " "
})

const styles = theme => {
    const height = 40;
    return {
        root: {
            display: 'flex',
            justifyContent: 'left',
            flexWrap: 'wrap',
            padding: theme.spacing.unit / 2,
        },
        label:{
            fontSize: 16
        },
        deleteIcon: {
            "&:hover":{
                color: 'white',
            }
        },
        avatar: {
            color: 'white',
            height,
            width:height
        },
        avatarImage: {
            height: 25,
            width: 25
        },
        colorPrimary:{
            '&:hover, &:focus': {
                backgroundColor: indigo['A700']
            },
            '&:active': {
                backgroundColor: indigo[900]
            },
        },
        chip: {
            margin: theme.spacing.unit / 2,
            height,
            borderRadius: height / 2
        },
        icon: {
            margin: theme.spacing.unit
        },
    }   
};
class LocationChip extends Component {
    constructor(props){
        super();
        this.state = { 
            location:props.location
        }
        this.interval = null
        this.getLabel = this.getLabel.bind(this)
    }

    getLabel() {
        const { location } = this.state;
            return <div>
                <FontAwesomeIcon icon={faClock} />&nbsp;{humanizeUptime(location.uptime)}
              </div>
    }
    componentDidMount() {
        this.interval=setInterval(()=>{
            this.setState(({location})=>{
                return { 
                    location: {
                    ...location,
                    uptime:location.uptime+1000,
                }
            }},()=>{
            })
        },1000)
    }
    componentWillUnmount(){
        clearInterval(this.interval);
    }
    handleConnect() {
        
    }
    render() {
        const { classes } = this.props;
        const { status, short_name, city, name, address } = this.state.location;

        
        const Working = <a href={`ts3server://${address || "public.top-ts.ru"}`}><FontAwesomeIcon className={classes.icon} icon={faTeamspeak} size="2x" /></a>
        const Pause = <FontAwesomeIcon className={classes.icon} icon={faPause} size="2x" />
        return ( 
            <div>
                <Chip 
                    onDelete={this.handleConnect} 
                    deleteIcon={status === "online" ? Working : Pause} 
                    className={classes.chip}
                    classes={{
                        label: classes.label,
                        avatar: classes.avatar,
                        deleteIcon: classes.deleteIcon,
                        colorPrimary: classes.colorPrimary,
                        avatarChildren: classes.avatarImage
                    }}
                    label={this.getLabel()}
                    color="primary" 
                    avatar={
                            <Avatar>
                                <Tooltip placement="bottomLeft" title={`${name}.${city}`}>
                                    <img style={{borderRadius:'50%'}} alt={`Локация ${short_name}`} src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.2.1/flags/1x1/${short_name.toLowerCase()}.svg`} />
                                </Tooltip>
                            </Avatar>
                    } 
                />
            </div>
        );
    }
}
 
export default withStyles(styles)(LocationChip);