import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { Helmet } from 'react-helmet';
import { Grid } from '@material-ui/core';
class NotFound extends Component {
  render() {
    return (
      <div align="center">
        <Helmet>
            <title>Ошибка 404 | TOP-TS.RU</title>
            <meta property="og:description" content="Страница не найдена 404 | TOP-TS.RU"/>
            <meta property="og:title" content="Страница не найдена 404 | TOP-TS.RU" />
            <meta property="og:url" content="https://top-ts.ru/" />
        </Helmet>
        Неправильно набран адрес, или такой страницы больше не существует, а возможно, никогда и не существовало.
        <br />
        <img src="https://www.ulmart.ru/maintenance/jackie.png" />
        <br />
        <Link to="/">
          <Button type="primary">Вернуться на главную</Button>
        </Link>
      </div>
    )
  }
}
export default NotFound;