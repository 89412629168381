import { Modal, Button } from 'antd';
import React, { Component } from 'react'

class ModalMessage extends Component {
  render() {
    const { title, message, footer = null, visible, hideFunction } = this.props;
    return (
      <div>
        <Modal
          title={title}
          visible={visible}
          onCancel={hideFunction}
          footer={footer}
          width={650}
        >
          {message}
        </Modal>
      </div>
    )
  }
}
export default ModalMessage;