import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet'
import { Button, Popover, Tag } from 'antd'
import MUIButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTeamspeak } from '@fortawesome/free-brands-svg-icons'
import { faRubleSign, faStar, faUserCheck, faShieldAlt, faCheckCircle, faServer, faWrench, faEquals } from '@fortawesome/free-solid-svg-icons'
import { faLifeRing } from '@fortawesome/free-regular-svg-icons'
import { Button as BSButton } from 'reactstrap';
import OrderForm from '../components/OrderForm';
import Feature from '../components/Feature';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo'
import red from '@material-ui/core/colors/red'
import WhyLicenseData from '../info/whylicense';
import WhyLicenseList from '../components/List';
import ModalMessage from '../components/ModalMessage'

const ym = window.ym;

const getIcon = icon => (
    <FontAwesomeIcon icon={icon} />
)
const styles = theme => ({
    root: {
      flexGrow: 1,
      maxWidth: 752,
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit * 2}px`,
    },
  });
class RentPage extends Component {
    constructor(){
        super();
        this.state={
            whyLicenseModal: false
        }
        this.hideWhyLicense = this.hideWhyLicense.bind(this);
        this.showWhyLicense = this.showWhyLicense.bind(this);
    }
    componentDidMount() {
        ym(40136605, 'hit','/rent')
    }
    
    showWhyLicense(){
        this.setState({ whyLicenseModal: true });
    }
    hideWhyLicense(){

        this.setState({ whyLicenseModal: false });
    }
    render() {
        const { classes } = this.props;
        return (
            <Container>
                <Helmet>
                    <title>Заказать голосовой сервер | TOP-TS.RU</title>
                    <meta name="description" content="Аренда голосового сервера. Самая низкая цена на лицензионные сервера. Высокая стабильность, постоянные обновления." />
                    <meta property="og:description" content="Отзывы наших клиентов | TOP-TS.RU"/>
                    <meta property="og:title" content="Заказать голосовой сервер | TOP-TS.RU" />
                    <meta property="og:url" content="https://top-ts.ru/rent" />
                </Helmet>
                <h3>Гарантия низкой цены!</h3>
                Нашли где-то лицензионные сервера в свободной продаже дешевле чем у нас ?
                Предоставим скидку!
                <div id="order">
                    <h1>Аренда сервера TS3 {<FontAwesomeIcon icon={faTeamspeak} />}</h1>
                    <OrderForm />
                </div>
                <Typography variant="h6" className={classes.title}>
                    Почему именно мы ?
                </Typography>
                <List dense={false}>
                    <Feature primary = "Самая низкая цена" secondary = {["Только лицензионные сервера всего за 9.1 рублей/слот. ", <a key={1} rel="noopener noreferrer" target="_blank" href="//vk.me/top_ts">Нашли дешевле ? Снизим цену!</a>]} 
                             icon={faRubleSign}
                             iconColor={indigo[500]}
                     />
                    <Feature primary = {["Официальная ", <a key={1} rel="noopener noreferrer" href="https://hosting.teamspeakusa.com/?athp=8cd40ce899de16d7d4f7b7dad61afa3d" target="_blank">ATHP лицензия</a>]}
                            secondary = {["Арендуйте голосовой сервер без лишних посредников и наценок. ",
                                                <Button key={0} onClick={this.showWhyLicense} type="primary">Почему это важно ?</Button>
                                        ]}
                            icon={faCheckCircle} 
                    />
                    <Feature primary = "Автоматическая установка"
                             secondary = "Ваш сервер доступен сразу же после оплаты. Данные о новом сервере отправляются на почту"
                             icon={faServer} 
                             iconColor="black"
                    />
                    <Feature primary = "Акция 30 + 10 слотов" secondary = {["При заказе сервера от 30 слотов и более ", <FontAwesomeIcon key={1} icon={faEquals} />, " 10 слотов бесплатно"]} 
                             icon={faStar}
                             iconColor="gold"
                    />
                    <Feature primary = "Полное управление" 
                             secondary = "Вы получаете полные привилегии на управление сервером" 
                             icon={faWrench} 
                             iconColor="black"
                    />
                    <Feature primary = "Защита от DDoS атак" 
                             secondary = "Ваши сервера размещаются на качественном оборудовании в одних из лучших датацентрах Москвы и Европы" 
                             icon={faShieldAlt} 
                             iconColor={deepPurple[900]}
                    />
                    <Feature primary = "Всегда готовы помочь" 
                             secondary = "Наша служба поддержки всегда готова помочь решить проблему или ответить на интересующие вопросы" 
                             icon={faLifeRing} 
                             iconColor={red[900]}
                    />
                </List>
                <ModalMessage 
                    title="Почему стоит выбрать лицензионные сервера ?"
                    visible={this.state.whyLicenseModal} 
                    hideFunction={this.hideWhyLicense}
                    message={[
                    "Арендовав лицензионный сервер Вы оградите себя от множества проблем пиратских хостингов, например: ",
                    <WhyLicenseList key={1} size="small" items={WhyLicenseData} />,
                    ]}
                    footer={[
                        <MUIButton key="submit" color="primary" onClick={this.hideWhyLicense}>
                          Хорошо
                        </MUIButton>
                      ]}
                />
            </Container>
        );
    }
}
 
export default withStyles(styles)(RentPage);