import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, Tag, InputNumber, Slider, Row, Col, Checkbox, Radio } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import {IntlProvider, FormattedMessage} from 'react-intl';
import { Button as MButton } from '@material-ui/core';
import ModalMessage from './ModalMessage';
import WhatIsButton from './WhatIsButton';
const has = require('lodash/has');
const RadioGroup = Radio.Group;
const definitions = {
    slots:{
        min: 10,
        max: 500,
        default: 30,
        regular:200,
        price:7
    },
    billingcycle:{
        default: 'monthly'
    }
}
const ym = window.ym;
class OrderServerConfig extends Component {
    constructor(){
        super();
        this.state={
            slots:30,
            address: "",
            domain: "",
            billingcycle:'monthly',
            needMore: false,
            modal: false,
            amount:0
        }
        this.hideLowestPriceModal = this.hideLowestPriceModal.bind(this)
        this.showLowestPriceModal = this.showLowestPriceModal.bind(this)
    }
    componentDidMount = () => {
        let Order = JSON.parse(localStorage.getItem('Order'))
        if (!Order)Order = {};
        if (!has(Order,'server')){
            Order.server= {}
        }
        if (!has(Order.server,'slots')){
            Order.server.slots = definitions.slots.default;
        }
        if (!has(Order.server,'billingcycle')){
            Order.server.billingcycle = definitions.billingcycle.default;
        }
        if (Order.server.slots > 200){this.setState({ needMore: true });}
        localStorage.setItem('Order',JSON.stringify(Order));
        this.setState({ ...Order.server })
        console.log('server mount')
    }
    hideLowestPriceModal () {
        this.setState({ modal: false});
    }
    showLowestPriceModal(){
        this.setState({ modal: true});
    }
    changebillingcycle = (e) => {
        const billingcycle = e.target.value;
        let Order = JSON.parse(localStorage.getItem('Order'))
        this.setState({ billingcycle }, ()=>{
            Order.server.billingcycle = billingcycle;
            localStorage.setItem('Order',JSON.stringify(Order));
        });
    }
    formatTooltip = (value)=>{
        return value + " " + (this.state.slots >= 30 ? " + 10 слотов в подарок" : "")
    }
    getSlots (){
     return <FormattedMessage
            id="slotsCount"
            defaultMessage={`{slots, number} {slots, plural,
            one {слот}
            other {слотов}
            }`}
            values={{slots: this.state.slots}}
        />
    }
    processMax = () => {
        const { slots } = definitions;

        return this.state.needMore === true ? slots.max : slots.regular
    }
    changeNeedmore = (e)=>{
        this.setState({ needMore : e.target.checked })
    }
    processbillingcycle = ()=>{
        let multiplier=1;
        switch (this.state.billingcycle)
        {
            case 'monthly':
                multiplier = 1;
                break;
            case 'quarterly':
                multiplier = 3;
                break;
            case 'semi-annually':
                multiplier=6;
                break;

            case 'annually':
                multiplier = 12;
                break;
            default:
                multiplier =1;
            break;
        }
        return multiplier;
    }
    getAmount = () => {
        const { slots } = definitions;
        const multiplier = this.processbillingcycle();
        const amount = this.state.slots * slots.price * multiplier ;
        return amount;
    }
    changeSlots = (value) => {
        let slots = value;
        let Order = JSON.parse(localStorage.getItem('Order'))
        this.setState({ slots }, ()=>{
            Order.server.slots = slots;
            localStorage.setItem('Order',JSON.stringify(Order));
        });
    }
    render() {
        const StockPopoverContent = () => {
            return (
                <div>
                    <p>При заказе сервера от 30 слотов и более мы добавляем 10 слотов бесплатно</p>
                    <p>Бонусные слоты начисляются автоматически и не учитываются при оплате сервера</p>
                    
                    {this.state.slots >= 30 ? "" : <Button type="primary" onClick={()=>{this.changeSlots(30)}}>Хочу сервер по акции!</Button>}
                </div>
            )
        }
        return (
            <div style={{userSelect:'none'}}>
                <p>Процесс заказа сервера максимально прост: выберите количество слотов, платёжный цикл после чего переходите к следующему шагу</p>
                <Tag color="#108ee9">Важно!</Tag> <p>Акция 30 + 10 <b>также действует</b> если Вы заказываете сервер <b>более чем на 30 слотов</b>!
                <br />
                <b>Слоты</b>- максимальное количество пользователей находящихся на сервере одновременно.
                <br />
                <b>Количество слотов можно всегда изменить без переустановки сервера!</b>
                </p>
                <b className="text-left">Выберите количество слотов: </b>
                <Row gutter={{sm: 16, xs: 16, md: 16}}>
                    <Col xs={6} md={3} sm={5}>
                        <InputNumber size="large" min={10} max={500} defaultValue={this.state.slots} value={this.state.slots} onChange={this.changeSlots}/>
                    </Col>
                    <Col xs={18} md={16} sm={13}>
                        <Slider 
                            min={10} 
                            max={this.processMax()} 
                            defaultValue={definitions.slots.default} 
                            value={this.state.slots} 
                            onChange={this.changeSlots}
                            tipFormatter={this.formatTooltip}
                        />
                    </Col>
                    <Col xs={15} md={5} sm={6}>
                        <Checkbox
                            checked={this.state.needMore}
                            onChange={this.changeNeedmore}
                            style={{
                                userSelect:'none'
                            }}
                        > Больше 200 слотов
                        </Checkbox>
                    </Col>
                </Row>
                <b className="text-left">Платёжный цикл: </b>
                <Row>
                    <RadioGroup style={{userSelect:'none'}} onChange={this.changebillingcycle} value={this.state.billingcycle}>
                            <Col xs={12} sm={6} md={6}>
                                <Radio value={'monthly'}>1 месяц</Radio>
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                                <Radio value={'quarterly'}>3 месяца</Radio>
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                                <Radio value={'semi-annually'}>6 месяцев</Radio>
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                                <Radio value={'annually'}>12 месяцев</Radio>
                            </Col>
                        </RadioGroup>
                </Row>
                <Row style={{marginTop:20}}>
                    <Col span={12}>
                    <span>Стоимость: </span><Tag color="#108ee9" style={{userSelect:'none', fontSize: 14 }}>{this.getAmount()} руб.</Tag>
                    </Col>
                    <Col span={12}>
                        <Popover placement="top" title={"Подробнее о акции 30 + 10"} content={StockPopoverContent()} trigger="click">
                            <Tag style={{userSelect:'none'}} color={this.state.slots >= 30 ? "#108ee9" : "#f50"}><FontAwesomeIcon icon={faQuestionCircle} fixedWidth size="lg" /> Акция 30 + 10 {this.state.slots >= 30 ? `действует` :"не действует" }</Tag>
                        </Popover>
                    </Col>
                </Row>
              <Row style={{marginTop: 20}}>
                <Button type="danger" className="float-left" onClick={this.showLowestPriceModal}>
                    Нашли дешевле ?
                </Button>
                <Button onClick={()=>{ym(40136605, 'reachGoal', 'orderForm_toBilling')}} className="float-right" href={`//my.top-ts.ru/cart.php?a=add&pid=2&configoption[5]=${this.state.slots}&billingcycle=${this.state.billingcycle}`} type="primary">
                  Заказать сервер
                </Button>
              </Row>
                <ModalMessage title="Гарантия низкой цены"
                    visible={this.state.modal} 
                    hideFunction={this.hideLowestPriceModal}
                    message={
                        <div>
                            <p style={{color: 'black'}}>Если вы нашли хостинг, который свободно предоставляет лицензионные сервера по цене ниже чем у нас- мы предоставим скидку в 10% от цены конкурента.</p>
                            <p style={{color: 'black'}}>Для получения скидки достаточно связаться с нами и предоставить данные.</p>
                        </div>
                    }
                    footer={
                        <div>
                            <Button href="#order" className="float-left" type = "primary" onClick={this.hideLowestPriceModal}>
                                Дешевле не нашёл, закажу у вас!
                            </Button>
                            <a target="_blank" rel="noopener" href="//vk.me/top_ts">
                                <Button type = "primary">
                                    Нашёл дешевле, хочу скидку!
                                </Button>
                            </a>
                        </div>
                    }
                />
            </div>
         );
    }
}
 
export default OrderServerConfig;