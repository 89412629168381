import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { Route, Switch } from "react-router-dom";
import Header from './components/Header';
import HomePage from './pages/home';
import RentPage from './pages/rent';
import StatusPage from './pages/status';
import FaqPage from './pages/faq'
import ReviewsPage from './pages/reviews';
import NotFoundPage from './pages/notfound404';
import Footer from './components/Footer'
import 'bootstrap/dist/css/bootstrap.css';
import "antd/dist/antd.css";
import { IntlProvider } from 'react-intl'

import ru_translations from './translations/ru.json'
const messages = {
    ru: ru_translations,
    //pl: pl_translations
}
class App extends Component {
  render() {
    return (
      <IntlProvider>
        <div>
          <Helmet>
            <meta property="og:image" content="https://my.top-ts.ru/templates/topTS/img/og_logo.png" />
            <meta property="og:type" content="website" />
          </Helmet>
          <Header />
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/rent" exact component={RentPage} />
              <Route path="/faq" exact component={FaqPage}/>
              <Route path="/status" exact component={StatusPage} />
              <Route path="/reviews" exact component={ReviewsPage} />
              <Route component={NotFoundPage} />
            </Switch>
          <Footer />
        </div>
      </IntlProvider>
    );
  }
}

export default App;
